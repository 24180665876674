footer {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: 100%;
  padding: 32px 16px 24px;
  box-sizing: border-box;
  border-top: 1px solid #e1e4e8;
}
footer .links {
  flex-direction: row;
  justify-content: center;
}

.number {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
}
.number .logout-btn {
  margin-left: 16px;
}

@media only screen and (max-width: 600px) {
  .number {
    flex-direction: column;
  }
  .number .logout-btn {
    margin-left: 0;
  }

  footer .links {
    flex-direction: column;
    text-align: center;
  }
}