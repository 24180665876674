@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;800&display=swap");

html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.text-center {
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  padding: 4px 24px;
  box-sizing: border-box;
  margin: 0 auto 32px;
}

#firebaseui-auth-container {
  width: 100%;
}

.highlight {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.5rem 0.5rem 0.5rem 0.125rem;
  background-color: #f4ebff;
}
