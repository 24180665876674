@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;800&display=swap);
html {
  -webkit-print-color-adjust: exact;
}

.digital-card {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 32px;
  text-align: center;
}
.digital-card .bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 1;
}
.digital-card .header,
.digital-card .content,
.digital-card .footer {
  z-index: 2;
}
.digital-card .header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.digital-card .header .avatar-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 125px;
  width: 125px;
}
.digital-card .header .avatar {
  height: 125px;
  width: auto;
  border-radius: 8px;
}
.digital-card .header .name {
  font-size: 24px;
  font-weight: bold;
  margin: 14px 0 4px;
}
.digital-card .header .title {
  font-size: 14px;
}
.digital-card .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  font-size: 16px;
}
.digital-card .content .description {
  margin: 8px 0 8px;
  white-space: pre-line;
}
.digital-card .content .icons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(64px, 1fr));
  grid-gap: 16px;
  width: 100%;
  text-align: center;
  border-radius: 40px;
  padding: 8px;
}
.digital-card .content .icons .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px;
}
.digital-card .content .icons .whatsapp {
  transform: scale(0.9);
}
.digital-card .footer {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  font-size: 13px;
}
.digital-card .footer .address {
  margin: 8px 0 16px;
}
.digital-card .footer .contact-container {
  display: flex;
}
.digital-card .footer .contact-container .phone {
  flex: 1 1;
  text-align: left;
}
.digital-card .footer .contact-container .email {
  flex: 1 1;
  text-align: right;
}

input,
textarea {
  display: block;
  width: 100%;
  text-align: center;
}

p,
a {
  margin: 0px;
  padding: 0px;
  color: unset;
  text-decoration: none;
}
.render {
  height: 100%;
}
.render #font-picker {
  display: none;
}
html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.text-center {
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  padding: 4px 24px;
  box-sizing: border-box;
  margin: 0 auto 32px;
}

#firebaseui-auth-container {
  width: 100%;
}

.highlight {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.5rem 0.5rem 0.5rem 0.125rem;
  background-color: #f4ebff;
}


footer {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: 100%;
  padding: 32px 16px 24px;
  box-sizing: border-box;
  border-top: 1px solid #e1e4e8;
}
footer .links {
  flex-direction: row;
  justify-content: center;
}

.number {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
}
.number .logout-btn {
  margin-left: 16px;
}

@media only screen and (max-width: 600px) {
  .number {
    flex-direction: column;
  }
  .number .logout-btn {
    margin-left: 0;
  }

  footer .links {
    flex-direction: column;
    text-align: center;
  }
}
