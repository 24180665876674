html {
  -webkit-print-color-adjust: exact;
}

.digital-card {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 32px;
  text-align: center;
}
.digital-card .bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 1;
}
.digital-card .header,
.digital-card .content,
.digital-card .footer {
  z-index: 2;
}
.digital-card .header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.digital-card .header .avatar-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 125px;
  width: 125px;
}
.digital-card .header .avatar {
  height: 125px;
  width: auto;
  border-radius: 8px;
}
.digital-card .header .name {
  font-size: 24px;
  font-weight: bold;
  margin: 14px 0 4px;
}
.digital-card .header .title {
  font-size: 14px;
}
.digital-card .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  font-size: 16px;
}
.digital-card .content .description {
  margin: 8px 0 8px;
  white-space: pre-line;
}
.digital-card .content .icons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(64px, 1fr));
  grid-gap: 16px;
  width: 100%;
  text-align: center;
  border-radius: 40px;
  padding: 8px;
}
.digital-card .content .icons .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px;
}
.digital-card .content .icons .whatsapp {
  transform: scale(0.9);
}
.digital-card .footer {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  font-size: 13px;
}
.digital-card .footer .address {
  margin: 8px 0 16px;
}
.digital-card .footer .contact-container {
  display: flex;
}
.digital-card .footer .contact-container .phone {
  flex: 1;
  text-align: left;
}
.digital-card .footer .contact-container .email {
  flex: 1;
  text-align: right;
}

input,
textarea {
  display: block;
  width: 100%;
  text-align: center;
}

p,
a {
  margin: 0px;
  padding: 0px;
  color: unset;
  text-decoration: none;
}